import request from '../utils/request.js'

//获取媒体列表
export function list(data) {
	return request({
		url: `/media/list`,
		method: 'get',
		params:data
	})
}
//编辑
export function edit(data) {
	return request({
		url: `/media/update`,
		method: 'put',
		params:data
	})
}
//删除
export function deletes(data) {
	return request({
		url: `/media/delete`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}