<template>
  <div class="bgcard">
		<div :class="filgs?'mask':'mask_a'" @click="maska"></div>
		<div class="searchlist" style="justify-content: space-between;">
			<div style="font-size: .16rem;color: #BBBBBB;">
				<span style="margin-right: .1rem;">{{$t('lang.Storagepeakmonth')}}：{{peak | amoutsa }}GB</span>
				<span>{{$t('lang.Currentusage')}}：{{amounts |amoutsa}}GB</span>
			</div>
			<div style="display: flex;">
				<div class="searchtime">
					<span style="color: #BBBBBB;">{{$t('lang.time')}}：</span>
					<el-date-picker v-model="time" type="daterange" :range-separator='$t("lang.to")' :start-placeholder='$t("lang.Startdate")' :end-placeholder='$t("lang.Enddate")' value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" @change="picker">
					</el-date-picker>
				</div>
				<div class="query">
					<div class="inputname">
						<input v-model="mediaName" :placeholder="$t('lang.pleaseEnterAFileName')" />
					</div>
					<div class="searchbtn">
						<button @click="search">
							<p>{{$t('lang.search')}}</p>
						</button>
					</div>
				</div>
			</div>
		</div>
    <div class="medos">
			<el-checkbox-group v-model="checkedCitiesa" class="medialist" @change="handleCheck">
				<el-checkbox v-for="(item, index) of medialist.list" :key="index" :label="item.id" >
						<div class="medisccon">
							<div style="width: 3.28rem;margin-right: .1rem;">
								<img class="videoimg" :src="item.thumbURL" />
								<div class="videocontent">
									<p style="width: 2.6rem;">{{ item.showName }}</p>
									<div class="videotime">
										<span>{{ item.createTime }}</span>
										<div class="imglist">
											<img @click.stop.prevent="download(item.downloadURL)" :src="require('@/assets/imgs/xiazai.png')" />
											<img @click.stop.prevent="deletemsg(item)" :src="require('@/assets/imgs/shanchu01.png')"/>
								      <div style="padding-left:5px" @click.stop.prevent="openmore(item,medialist.list)">
											  <img  :src="require('@/assets/imgs/more.png')" style="width:7px;"/>
								      </div>
										</div>
										<div class="morelist" v-if="item.show">
											<p @click.stop.prevent="detailsnews(item)">{{ $t("lang.detailedInformation") }}</p>
											<p @click.stop.prevent="modifyname(item)">{{ $t("lang.nameModification") }}</p>
											<p @click.stop.prevent='copy(item)'>{{ $t("lang.copyAddressa") }}</p>
											<div class="triangle"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</el-checkbox>
			</el-checkbox-group>
			
      <!-- 详情信息 -->
      <el-dialog
        :visible.sync="detailsmsg"
        width="25%"
        top="3.55rem"
        :title="$t('lang.detailedInformation')"
        :show-close="false"
        custom-class="detailsmsg"
      >
        <div>
          <p style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis">{{ $t("lang.Programname") }}：{{ showlist.showName }}</p>
          <p>{{ $t("lang.creationTime") }}：{{ showlist.createTime }}</p>
          <p>{{ $t("lang.programDuration") }}：{{ showlist.length | secondsFormat}}</p>
          <p>{{ $t("lang.sourceEquipment") }}：{{ showlist.deviceName }}</p>
          <p>{{ $t("lang.Programsize") }}：{{ showlist.size }}MB</p>
        </div>
        <div>
          <span class="dialog-footer">
            <button @click="detailsmsg = false">{{ $t("lang.return") }}</button>
          </span>
        </div>
      </el-dialog>
      <!-- 修改名称 -->
      <el-dialog
        :visible.sync="showmsg"
        width="4.18rem"
        top="3.15rem"
        :title="$t('lang.nameModification')"
        :show-close="false"
        custom-class="showmsg"
				 :close-on-click-modal='false'
      >
        <input v-model="modifymsg" />
        <div>
          <span class="dialog-footer">
            <button @click="showmsg = false">{{ $t("lang.cancel") }}</button>
            <button @click="changeName">{{ $t("lang.determine") }}</button>
          </span>
        </div>
      </el-dialog>
      <!-- 删除文件 -->
      <el-dialog
        :visible.sync="deletevideo"
        width="4.18rem"
        top="3.53rem"
        :title="$t('lang.Deletefile')"
        :show-close="false"
        custom-class="deletevideo"
      >
				<p v-if="tipsa">{{$t("lang.Batchdelete")}}</p>
        <p v-else>
				{{$t("lang.Areyousureyouwanttodeletethefile")}}<span style="color: #438749;">{{modifymsg}}</span>{{$t("lang.Isthatright")}}？
				</p>
				
        <div>
          <span class="dialog-footer">
            <button @click="deletevideo = false">{{ $t("lang.cancel") }}</button>
            <button @click="deletesa">{{ $t("lang.determine") }}</button>
          </span>
        </div>
      </el-dialog>
			<div class="yet" v-if="!medialist.list">
				<span>{{$t("lang.tableNull")}}</span>
			</div>
    </div>
		<div class="paginat"  v-if="medialist.list">
      <div class="total"><span>{{$t("lang.Total")}}</span> {{medialist.total}} <span>{{$t("lang.Strip")}}</span></div>
			<el-pagination background ref="pagination" layout="prev, pager, next" :page-size="12" :total="medialist.total" @current-change="handlePageCurrentChange" :prev-text='$t("lang.previouspage")' :next-text='$t("lang.nextpage")'>
			</el-pagination>
			<el-checkbox v-model="checkAll" @change="handleCheckAllChange">{{$t("lang.Selectall")}}</el-checkbox>
			<el-button size="small" style="margin-left: .1rem;" @click='delles'>{{$t("lang.Batchdeletes")}}</el-button>
		</div>
  </div>
</template>

<script>
import * as media from "@/api/media.js";
import { imgUrl } from "@/utils/request";
export default {
  data() {
    return {
      url: "",
      time: "",
      mediaName: "",
      deletevideo: false, //删除
      showmsg: false, //修改
      detailsmsg: false, //详细
      modifymsg: "", //文件名称
      mediaid: "", //文件id
      parsm: {
        nameOrEmail: "",
        page: 1,
        size: 12,
        model: "",
        createTime: "",
        stopTime: "",
        email: "",
      },
      medialist: [],
      showlist: [],
			checkedCities:[],
			checkedCitiesa:[],
			checkAll:false,
			tipsa:false,
			filgs:false,
			peak:'',
			amounts:''
    };
  },
  created() {
    this.url = imgUrl;
  },
  mounted() {
    this.parsm.email = JSON.parse(sessionStorage.getItem("user")).email;
    this.getlist();
  },
	filters: {
		secondsFormat(value) {
			let result = parseInt(value)
			let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
			let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
			let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
			result = `${h}:${m}:${s}`
			return result
		},
		amoutsa(val){
			if(val){
				return val.toFixed(4)
			}else{
				return val
			}
		}
	},
  methods: {
    //时间筛选
    picker(e) {
      console.log(e);
      this.parsm.page = 1;
      this.parsm.size = 12;
      if (e) {
        this.parsm.createTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.createTime = "";
        this.parsm.stopTime = "";
      }
      this.getlist();
    },
    //文件名筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 12;
      this.parsm.nameOrEmail = this.mediaName;
      this.getlist();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getlist();
			this.checkedCitiesa=[]
			this.checkAll=false
    },
    //获取媒体文件
    getlist() {
      media.list(this.parsm).then((res) => {
        //console.log(res);
				this.checkedCities=[]
        if (res.data) {
          this.medialist = res.data;
					this.peak=this.medialist.peak
					this.amounts=this.medialist.amount
          this.medialist.list.forEach((item) => {
						this.checkedCities.push(item.id)
            item.show = false;
          });
        }else{
					this.medialist.list=null
				}
      });
    },
    //下载文件
    download(e) {
      window.location.href = e;
    },
    //弹出更多
    openmore(row,res) {
      //console.log(row);
			this.$set(row, "show", true);
      this.filgs=true
      this.$forceUpdate();
    },
    //详细信息
    detailsnews(item) {
			this.filgs=false
      console.log(item);
      this.detailsmsg = true;
			this.tipsa=false
      this.showlist = item;
      this.$set(item, "show", false);
      this.$forceUpdate();
    },
    //打开删除文件模态框
    deletemsg(item) {
      this.deletevideo = true;
      this.modifymsg = item.showName;
      this.mediaid = item.id;
			this.tipsa=false
    },
    //删除文件
    deletesa() {
			let das=[this.mediaid]
			if(this.tipsa){
				das=this.checkedCitiesa
			}
			//console.log(das)
			media.deletes(das).then((res) => {
				this.$message.success(this.$t("lang.Operations"));
				this.deletevideo = false;
				this.isLastPage();
				this.getlist();
				this.checkedCitiesa=[]
				this.checkAll=false
			});
    },
	// 判断当前页是否是最后一页
		isLastPage() {
			let lastPage = Math.ceil((this.medialist.total - 1) / this.parsm.size)
			if(this.tipsa){
				lastPage = Math.ceil((this.medialist.total - this.checkedCitiesa.length) / this.parsm.size)
			}
			if(lastPage==0){
				lastPage=1
			}
			 //console.log(lastPage)
			if (this.parsm.page === lastPage || this.parsm.page > lastPage) {
				this.parsm.page = lastPage
			}
		},
    //打开编辑文件模态框
    modifyname(item) {
			this.filgs=false
      console.log(item);
      this.showmsg = true;
			this.tipsa=false
      this.modifymsg = item.showName;
      this.mediaid = item.id;
      this.$set(item, "show", false);
      this.$forceUpdate();
    },
    //复制路径
    copy(row) {
			let addr=window.location.origin+row.downloadURL
      this.$copyText(addr).then(
        (res) => {
          this.$message.success(this.$t("lang.Copysucceeded"));
          this.$set(row, "show", false);
        },
        (error) => {
          this.$message.success(this.$t("lang.copyfailed"));
        }
      );
			this.filgs=false
      this.$forceUpdate();
    },
    //修改名称
    changeName() {
      let data = {
        id: this.mediaid,
        name: this.modifymsg,
      };
      media.edit(data).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.showmsg = false;
        this.getlist();
      });
    },
		//单选
		handleCheck(value){
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.checkedCities.length;
			this.checkedCitiesa=value
			//console.log(this.checkedCitiesa)
		},
		//全选
		handleCheckAllChange(val){
			 this.checkedCitiesa = val ? this.checkedCities : [];
		},
		//批量删除
		delles(){
			if(this.checkedCitiesa.length<1){
				this.$message.error(this.$t("lang.Pleaseselectdelete"))
				return false
			}
			this.tipsa=true
			this.deletevideo = true;
		},
		//遮罩
		maska(){
			this.filgs=false
			this.medialist.list.forEach((item) => {
			  item.show = false;
			});
		}
  }
};
</script>

<style lang="less" scoped>
.bgcard {
	min-height: calc(100vh - 1.35rem);
	background: #222628;
	border-radius: 0.02rem;
	padding: .3rem;
	box-sizing: border-box;
	.mask{
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 99998;
	}
	.mask_a{
		display: none;
	}
}
.mediaheader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: .16rem;
  p {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #bbbbbb;
    margin-right: .13rem;
  }
  input,
  button {
    outline-style: none;
    border: none;
  }
  .searchthree {
    display: flex;
    align-items: center;
    margin-right: 21px;
    input {
      width: 2.81rem;
      height: .50rem;
      text-indent: 21px;
      background: #222628;
      border: 1px solid #9a9a9a;
      border-radius: 2px;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #bbbbbb;
    }
  }
  button {
    width: .92rem;
    height: .5rem;
    background: #438749;
    border-radius: .1rem;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
/deep/ .medialist {
  margin-top: .2rem;
  display: flex;
  flex-flow: row wrap;
	//justify-content: space-around;
	.el-checkbox{
		margin-right: .2rem;
		display: flex;
		cursor: default;
		align-items: flex-end;
		margin-bottom: .27rem;
		position: relative;
		.el-checkbox__inner{
			 background: #EDEDED;
			border:1px solid #DCDFE6;
		}
		.el-checkbox__inner::after{
			border:2px solid #000;
			border-left:0;
			border-top:0;
		}
		.el-checkbox__input{
			position: absolute;
			z-index: 9;
			bottom:.58rem;
			right: .26rem;
		}
	}
  .medisccon {
		display: flex;
		align-items: flex-end;
    width: 3.28rem;
    height: 2.7rem;
    background: #25292c;
    border-radius: 4px;
    //margin-right: .4rem;
    
    .videoimg {
      width: 3.28rem;
      height: 1.76rem;
      border-radius: 4px;
    }
    .videocontent {
      padding: .13rem .16rem .13rem .16rem;
      P {
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #bbbbbb;
        margin-bottom: .2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .videotime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        span {
          font-size: .14rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #bbbbbb;
        }
        .imglist {
          display: flex;
          cursor: pointer;
          img {
            &:first-of-type {
              width: .23rem;
              height: .23rem;
              margin-right: .14rem;
            }
            &:nth-of-type(2) {
              width: .21rem;
              height: .23rem;
              margin-right: .16rem;
            }
            &:last-of-type {
              width: .21rem;
              height: .23rem;
              // margin-right: 16px;
              cursor: pointer;
            }
          }
        }
        .morelist {
					z-index: 99999;
          cursor: pointer;
          position: absolute;
          width: .8rem;
          background: #c8c8c8;
          border-radius: 4px;
          padding: .17rem .13rem .17rem .11rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          right: -2px;
          bottom: .44rem;
          p {
            font-size: .14rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: .24rem;
          }
          .triangle {
            width: 0px;
            height: 0px;
            border-width: .1rem;
            border-style: solid;
            border-color: #c8c8c8 transparent transparent transparent;
            position: absolute;
            bottom: -.18rem;
            right: .09rem;
          }
        }
      }
    }
  }
}
.yet {
  height: .7rem;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-end;
  font-size: .15rem;
  color: #909399;
}

/deep/.paginat {
  display: flex;
	align-items: center;
  .total {
    width: auto;
    height: .42rem !important;
    line-height: .42rem;
    padding: 0px .15rem;
    margin-top: 2px;
    box-sizing: border-box;
    background: #222628;
    border: 1px solid #464748;
    border-radius: 2px;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
  }
  .el-pagination span {
    width: auto;
    padding: .06rem .05rem;
    line-height: .28rem;
  }
	.el-checkbox__label{
		font-size: .16rem;
		color: #B2B2B2;
	}
	.el-checkbox__inner{
		 background: #EDEDED;
		border:1px solid #DCDFE6;
	}
	.el-checkbox__inner::before{
		background-color: #000;
	}
	.el-checkbox__inner::after{
		border:2px solid #000;
		border-left:0;
		border-top:0;
	}
}
/deep/.el-date-editor{
  .el-range__icon{
    line-height: .3rem;
  }
  .el-range-input{
    font-size: .14rem;
  }
  .el-range-separator{
    line-height: .27rem;
    font-size: .14rem;
  }
  .el-range__close-icon{
    font-size: .14rem;
    width: .25rem;
    line-height: .3rem;
  }
}
/deep/ .el-checkbox__label{
	padding-left: 0;
}
</style>
